import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Suspense } from 'react'
import AppRouter from './routes'
import AuthContext from './context/AuthContext'
import AppThemeProvider from './context/ThemeContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLoader from './views/components/AppLoader'

export interface IApplication { }
const Application: React.FC<IApplication> = () => {
    return (
        <AuthContext>
            <AppThemeProvider>
                <Router>
                    <Suspense fallback={<AppLoader />}>
                        <ToastContainer />
                        <AppRouter />
                    </Suspense>
                </Router>
            </AppThemeProvider>
        </AuthContext>
    )
}

export default Application
