import React from 'react';
import { CircularProgress, Container } from '@mui/material';

const AppLoader: React.FC<{ height?: string }> = ({ height = "100vh" }) => {
    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height,
            }}
        >
            <CircularProgress color='primary' size={'64px'} />
        </Container>
    );
};

export default AppLoader;
