import { useEffect, useState } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Config from 'src/config';

type UseAxiosResponse<T> = {
  data: T | null;
  loading: boolean;
  error: AxiosError<T> | null;
};

type UseAxiosHook<T> = [
  (config: AxiosRequestConfig) => void,
  UseAxiosResponse<T>
];
const AppAuthToken = localStorage.getItem(Config.AuthKey)

const useAxios = <T>(): UseAxiosHook<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError<T> | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(AppAuthToken);

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: Config.baseApiURL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });

  // Intercept all responses
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse<T>) => {
      setData(response.data);
      setLoading(false);
      return response;
    },
    (err: AxiosError<T>) => {
      setError(err);
      setLoading(false);
      throw err;
    }
  );

  // Intercept error responses with status codes 401 and 409
  axiosInstance.interceptors.response.use(
    undefined,
    (error: AxiosError<T>) => {
      if (error.response && (error.response.status === 401 || error.response.status === 409)) {
        localStorage.removeItem(Config.AuthKey);
        setAuthToken(null);
      }
      return Promise.reject(error);
    }
  );

  const makeRequest = async (config: AxiosRequestConfig) => {
    try {
      setLoading(true);
      await axiosInstance.request(config);
    } catch (err: any) {
      setError(err);
    }
  };

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === Config.AuthKey) {
      setAuthToken(event.newValue);
    }
  };

  useEffect(() => {
    const storedAuthToken = localStorage.getItem(Config.AuthKey);
    if (storedAuthToken) {
      setAuthToken(storedAuthToken);
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // useEffect(() => {
  //   if (authToken) {
  //     localStorage.setItem(Config.AuthKey, authToken);
  //   } else {
  //     localStorage.removeItem(Config.AuthKey);
  //   }
  // }, [authToken]);

  const initAxiosQuery: UseAxiosResponse<T> = {
    data,
    loading,
    error,
  };

  return [makeRequest, initAxiosQuery];
};

export default useAxios;
