import React, { useEffect, useRef } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { PaletteMode } from '@mui/material'
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { LinkProps } from '@mui/material/Link'

import ColorModeContext from './colorModeContextProvider'
import ThemeContextType from './type'

export interface IThemeProps {
    children?: React.ReactNode
}

declare module '@mui/material/styles' {
    interface Palette {
        layer1: Palette['primary']
        layer2: Palette['primary']
        layer3: Palette['primary']
        textColor1: Palette['primary']
        textColor2: Palette['primary']
        textColor3: Palette['primary']
        textColor4: Palette['primary']
        textColor5: Palette['primary']
        textColor6: Palette['primary']
        borderColor1: Palette['primary']
        borderColor2: Palette['primary']
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        layer1?: PaletteOptions['primary']
        layer2?: PaletteOptions['primary']
        layer3?: PaletteOptions['primary']
        textColor1?: PaletteOptions['primary']
        textColor2?: PaletteOptions['primary']
        textColor3?: PaletteOptions['primary']
        textColor4?: PaletteOptions['primary']
        textColor5?: PaletteOptions['primary']
        textColor6?: PaletteOptions['primary']
        borderColor1?: PaletteOptions['primary']
        borderColor2?: PaletteOptions['primary']
    }
}

const themeKey = 'APP_THEME'

const AppThemeProvider: React.FC<IThemeProps> = ({ children }) => {
    const [mode, setMode] = React.useState<PaletteMode>('light')
    const [isToggled, setIsToggled] = React.useState<boolean>(false)
    const mounted = useRef(true)

    const getTheme = () => {
        const value = localStorage.getItem(themeKey)
        return value === null ? 'light' : (value as 'light' | 'dark')
    }

    const setTheme = (value: 'light' | 'dark') => {
        localStorage.setItem(themeKey, value)
    }

    const updateTheme = async () => {
        const value = getTheme()
        setMode(value)
        await setTheme(value)
    }

    useEffect(() => {
        if (mounted.current) {
            updateTheme()
            mounted.current = false
            return
        }
    }, [])

    useEffect(() => {
        if (mode !== undefined) {
            setTheme(mode)
        }
    }, [isToggled])

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
        setIsToggled((v) => !v)
    }

    // const colorMode = React.useMemo(
    //     () => ({
    //         toggleColorMode: () => {
    //             setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    //         },
    //     }),
    //     [],
    // );

    const LinkBehavior = React.forwardRef<
        any,
        Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
    >((props, ref) => {
        const { href, ...other } = props
        // Map href (MUI) -> to (react-router)
        return (
            <RouterLink
                data-testid="custom-link"
                ref={ref}
                to={href}
                {...other}
            />
        )
    })

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                    ...(mode === 'light'
                        ? {
                            primary: {
                                main: '#0D4FAD',
                            },
                            secondary: {
                                main: '#F2B827',
                                // contrastText: 'rgba(255,255,255,0.87)',
                            },
                            background: {
                                default: '#FFFFFF',
                            },
                            success: {
                                main: '#2BB265',
                            },
                            error: {
                                main: '#E74444',
                            },
                            warning: {
                                main: '#F2F619',
                            },
                            layer1: {
                                main: '#FFFFFF',
                            },
                            layer2: {
                                main: '#F0F6F6',
                            },
                            layer3: {
                                main: '#FBFCFC',
                            },
                            textColor1: {
                                main: '#FFFFFF',
                            },
                            textColor2: {
                                main: '#000000',
                            },
                            textColor3: {
                                main: '#1A1A1A',
                            },
                            textColor4: {
                                main: '#808080',
                            },
                            textColor5: {
                                main: '#A8BBBB',
                            },
                            textColor6: {
                                main: '#FFFFFF',
                            },
                            borderColor1: {
                                main: '#E1E1E1',
                            },
                            borderColor2: {
                                main: '#999999',
                            },
                        }
                        : {
                            primary: {
                                main: '#0D4FAD',
                            },
                            secondary: {
                                main: '#03B696',
                                // contrastText: 'rgba(255,255,255,0.87)',
                            },
                            background: {
                                default: '#171A18',
                            },
                            success: {
                                main: '#2BB265',
                            },
                            error: {
                                main: '#E74444',
                            },
                            warning: {
                                main: '#F2F619',
                            },
                            layer1: {
                                main: '#171A18',
                            },
                            layer2: {
                                main: '#232725',
                            },
                            layer3: {
                                main: '#1D221F',
                            },
                            textColor1: {
                                main: '#D6E5DD',
                            },
                            textColor2: {
                                main: '#BECEC5',
                            },
                            textColor3: {
                                main: '#889C91',
                            },
                            textColor4: {
                                main: '#CCFCE0',
                            },
                            textColor5: {
                                main: '#5A6760',
                            },
                            textColor6: {
                                main: '#FFFFFF',
                            },
                            borderColor: {
                                main: '#2E3631',
                            },
                        }),
                },
                typography: {
                    // fontFamily: "'Overpass', sans-serif",
                    fontFamily: "'Poppins', sans-serif",
                },
                components: {
                    MuiLink: {
                        defaultProps: {
                            component: LinkBehavior,
                        } as LinkProps,
                    },
                    MuiButton: {
                        defaultProps: {
                            LinkComponent: LinkBehavior,
                            disableRipple: true,
                        },
                        // styleOverrides: {
                        // 	containedPrimary: {
                        // 		// borderBottom: '3px #035FA1 solid',
                        // 	},
                        // 	root: {
                        // 		// borderRadius: '20px',
                        // 		textTransform: 'capitalize',
                        // 	},
                        // },
                    },
                },
            }),
        [mode]
    )

    const initialState: ThemeContextType = {
        theme,
        toggleColorMode: toggleColorMode,
        mode: mode as 'light' | 'dark',
    }

    return (
        <ColorModeContext.Provider value={initialState}>
            <ThemeProvider theme={theme}>
                <CssBaseline>{children}</CssBaseline>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default AppThemeProvider
