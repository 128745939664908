import { useRoutes, Navigate } from 'react-router-dom'
import { lazy, useContext } from 'react'
import AuthContext from '../context/AuthContext/AuthContext'
import RouteOutlet from './RouteOutlet'

const Home = lazy(() => import('../views/pages/Home'))
const About = lazy(() => import('../views/pages/About'))
const NotFound = lazy(() => import('../views/pages/NotFound'))
const Login = lazy(() => import('../views/pages/Login'))
const Register = lazy(() => import('../views/pages/Register'))
const ForgotPassword = lazy(() => import('../views/pages/ForgotPassword'))
const Course = lazy(() => import('../views/pages/Course'))
const CompleteRegistration = lazy(() => import('../views/pages/CompleteRegistration'))
const Library = lazy(() => import('../views/pages/Library'))
const Books = lazy(() => import('../views/pages/Books'))
const Account = lazy(() => import('../views/pages/Account'))
const EditPassword = lazy(() => import('../views/pages/Account/EditPassword'))
const EditProfile = lazy(() => import('../views/pages/Account/EditProfile'))
const Subscription = lazy(() => import('../views/pages/Subscription'))
const History = lazy(() => import('../views/pages/Subscription/History'))
const News = lazy(() => import('../views/pages/News'))
const ViewNews = lazy(() => import('../views/pages/News/ViewNews'))
const FoundationCourses = lazy(() => import('../views/pages/FoundationCourses'))
const Watched = lazy(() => import('../views/pages/FoundationCourses/Watched'))
const Favorite = lazy(() => import('../views/pages/FoundationCourses/Favorite'))
const Courses = lazy(() => import('../views/pages/Courses'))
const AddonCourses = lazy(() => import('../views/pages/AddonCourses'))

const AppRouter: React.FC = () => {
    const { isLoggedIn } = useContext(AuthContext)
    let AppRoutes = useRoutes([
        {
            path: '/',
            element: <RouteOutlet />,
            children: [
                {
                    index: true,
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Home />,
                },
                {
                    path: '/course/:id',
                    element: isLoggedIn ? (
                        <Course />
                    ) : (
                        <Navigate
                            to="/login"
                            state={{ authRedirectFrom: '/about' }}
                        />
                    ),
                },
                {
                    path: '/courses',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Courses />,
                },
                {
                    path: '/onboard',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <CompleteRegistration />,
                },
                {
                    path: '/library',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Library />,
                },
                {
                    path: '/subscription',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Subscription />,
                },
                {
                    path: '/addon-courses',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <AddonCourses />,
                },
                {
                    path: '/foundation-courses',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <FoundationCourses />,
                },
                {
                    path: '/foundation-courses/watched',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Watched />,
                },
                {
                    path: '/foundation-courses/favorites',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Favorite />,
                },
                {
                    path: '/news',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <News />,
                },
                {
                    path: '/news/view/:id',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <ViewNews />,
                },
                {
                    path: '/subscription/history',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <History />,
                },
                {
                    path: '/books',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Books />,
                },
                {
                    path: '/account',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <Account />,
                },
                {
                    path: '/account/settings',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <EditProfile />,
                },
                {
                    path: '/account/change-password',
                    element: !isLoggedIn ? <Navigate to="/login" /> : <EditPassword />,
                },
                {
                    path: '/login',
                    element: isLoggedIn ? <Navigate to="/" /> : <Login />,
                },
                {
                    path: '/register',
                    element: isLoggedIn ? <Navigate to="/" /> : <Register />,
                },
                {
                    path: '/forgot-password',
                    element: isLoggedIn ? (
                        <Navigate to="/" />
                    ) : (
                        <ForgotPassword />
                    ),
                },
                {
                    path: '*',
                    element: <NotFound />,
                },
            ],
        },
    ])

    if (isLoggedIn === undefined) return <></>
    return AppRoutes
}

export default AppRouter
