import React, { useReducer, useEffect } from 'react'
import Config from '../../config'
import { appCookie } from '../../utils'
import AuthContextComponent from './AuthContext'
import IAuthContext from './types'
import { User } from 'src/interfaces'
import $axios from 'src/Plugins/http'
import useAxios from 'src/hooks/useAxios'

export interface IAuthContextProps {
    children?: React.ReactNode
}
const AuthToken = localStorage.getItem(Config.AuthKey)
const AuthContext = ({ children }: IAuthContextProps): React.ReactElement => {
    const [getUser, getUserQuery] = useAxios<{ loggedInUser: User }>()
    const reducer: React.Reducer<IAuthContext, Partial<IAuthContext>> = (
        state,
        action
    ): IAuthContext => {
        state = { ...state, ...action }
        return state
    }

    const authenticate = async (
        status: boolean,
        token: string,
        user?: User
    ) => {
        // status
        //     ? appCookie.setCookie(Config.AuthKey, token)
        //     : appCookie.removeItem(Config.AuthKey)
        status
            ? localStorage.setItem(Config.AuthKey, token)
            : localStorage.removeItem(Config.AuthKey)
        dispatch({
            isLoggedIn: true,
            user: user,
        })
        return Promise.resolve(true)
    }

    const updateUser = (user: User) => {
        dispatch({
            user: { ...user },
        })
    }

    const checkAndUpdateUserData = () => {
        if (AuthToken !== null) {
            getUser({
                url: '/current-user',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } else {
            dispatch({
                isLoggedIn: false,
            })
            localStorage.removeItem(Config.AuthKey)
        }
    }

    useEffect(() => {
        const { data, error } = getUserQuery
        if (data !== null) {
            dispatch({
                isLoggedIn: true,
                user: { ...data.loggedInUser },
            })
        }
        if (error) {
            dispatch({
                isLoggedIn: false,
                user: undefined,
            })
            localStorage.removeItem(Config.AuthKey)
        }
    }, [getUserQuery.loading])

    const logout = async () => {
        localStorage.removeItem(Config.AuthKey)
        dispatch({
            isLoggedIn: false,
            user: undefined,
        })
        return Promise.resolve(true)
    }

    const [state, dispatch] = useReducer(reducer, {
        authenticate,
        updateUser,
        logout,
    } as IAuthContext)

    useEffect(() => {
        if (state.isLoggedIn === undefined) {
            // console.log('hey', AuthToken)
            dispatch({
                isLoggedIn: AuthToken !== null ? true : false,
            })
            checkAndUpdateUserData()
        }
        return () => {}
    }, [state.isLoggedIn])

    return (
        <AuthContextComponent.Provider
            value={{
                ...state,
                authenticate,
                updateUser,
                dispatch,
                logout,
            }}
        >
            {children}
        </AuthContextComponent.Provider>
    )
}

export default AuthContext
